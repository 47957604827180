import { OAuthSetting } from '@tracktik/tt-authentication'

/**
 * Needs to match TTC settings:
 * https://github.com/TrackTik/ansible/blob/master/roles/deployment-ttc/templates/clients.json.j2
 *
 * If no scopes provided, the API will provide the default scopes.
 */
export const DATALAB_OAUTH_SETTINGS: OAuthSetting = {
  clientName: 'datalab',
  clientId: 'b8891d13600b7fd75266b3cc',
} as const
